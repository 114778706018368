/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import postscribe from "postscribe";
import { getSrc, StaticImage } from "gatsby-plugin-image";
import BestBanksDefault from "../best-banks/best-banks-default";

import Collapse from "react-bootstrap/Collapse";

// Layout
import SecondaryLanding from "../../layouts/secondary-landing";

// Styles
import "./branch.bootstrap.scss";

// Components
import Breadcrumb from "../breadcrumb/breadcrumb-auto";
import Button from "../custom-widgets/button";
import Icon from "../custom-widgets/icon";
import DailyHours from "../branch-info/daily-hours";
import BranchServices from "../branch-info/branch-services";
import GoogleMapSimple from "../google-map/google-map-simple";
import SEO from "../../components/seo/seo";
import Modal from "react-bootstrap/Modal";
import HolidaysModal from "../holidays-modal";
import styles from "../../pages/commercial-banking/treasury-management-services/express.module.scss";
import MobileAppDownloadBtn from "../mobile-online-banking/mobile-app-download-btn";
import NotificationAlert from "../../components/notifications/notification-alert";
import BankerHeadshot from "../../components/banker-headshot";
import LoanOfficerSearch from "../loan-officers/loan-officer-search";

export const query = graphql`
  query branchQuery($path: String) {
    branch: strapiBranchLocations(PageURL: { eq: $path }) {
      PageURL
      Title
      BranchId
      YextCode
      MSBookingsBranchName
      Address {
        City
        State
        StreetAddress
        ZipCode
        BranchGeoLocation {
          id
          Lat
          Lng
          Zoom
        }
      }
      PhoneNumber
      EmailAddress
      DisruptionNotice
      BranchImageUrl
      BranchServices {
        TellerServices
        WalkUpATM
        DriveThroughATM
        DriveThroughBanking
        SafeDepositBoxes
      }
      LobbyHours {
        AllClosed
        Friday
        Monday
        Saturday
        Sunday
        Thursday
        Tuesday
        Wednesday
      }
      DriveUpHours {
        AllClosed
        Friday
        Monday
        Saturday
        Sunday
        Thursday
        Tuesday
        Wednesday
      }
      SEO {
        MetaDescription
        MetaTitle
        Schema {
          address {
            _type
            addressCountry
            addressLocality
            addressRegion
            postalCode
            streetAddress
          }
          _context
          _type
          amenityFeature {
            _type
            hoursAvailable {
              _type
              closes
              dayOfWeek
              opens
            }
            name
            value
          }
          branchCode
          email
          geo {
            _type
            latitude
            longitude
          }
          image
          name
          openingHoursSpecification {
            _type
            closes
            dayOfWeek
            opens
          }
          parentOrganization {
            _type
            logo
            name
            url
          }
          telephone
          url
        }
      }
      OgImageUrl
      branch_state {
        StateCode
        Slug
      }
      loan_officers {
        FirstName
        LastName
        JobTitle
        EmailAddress
        ProfilePhoto {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      mortgage_bankers {
        Active
        FirstName
        LastName
        JobTitle
        EmailAddress
        ProfilePhoto {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    defaultBranchPhoto: file(relativePath: { eq: "branches/wafd-bank-branch-default-1000.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 100
          width: 1200
          transformOptions: { cropFocus: CENTER }
          layout: CONSTRAINED
        )
      }
    }
  }
`;

const BranchTemplate = ({ location, data }) => {
  const branch = data.branch;
  const StateCode = branch.branch_state.StateCode;

  useEffect(() => {
    branch.YextCode &&
      postscribe(
        "#branch-reviews",
        `<script src=https://sites.yext.com/205067-reviews.js?storeCode=${branch.YextCode} ></script>`
      );
  }, []);

  // 2024 Holidays Modal
  const [showHolidayModal, setShowHolidayModal] = useState(false);
  const handleCloseModal = () => setShowHolidayModal(false);
  const handleShowModal = (e) => {
    e.preventDefault();
    setShowHolidayModal(true);
  };

  // TODO: the case sensitivity of these keys must match what is in Strapi data, too brittle.
  let weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  let now = new Date();

  const [today, setToday] = useState("");
  function getWeekDay(date) {
    let day = date.getDay();
    return weekdays[day];
  }
  useEffect(() => {
    let weekDay = getWeekDay(now);
    setToday(weekDay);
  }, []);

  const todaysLobbyHours = branch.LobbyHours.AllClosed
    ? "Closed"
    : branch.LobbyHours[today] !== null
    ? branch.LobbyHours[today]
    : "Unknown";

  const todaysLobbyHoursHtml = todaysLobbyHours?.match(/closed/i)
    ? "Lobby <strong>Closed Today</strong>"
    : "Lobby Hours Today: <strong>" + todaysLobbyHours + "</strong>";

  // Google Maps Data
  const GMData = {
    center: {
      lat: branch.Address.BranchGeoLocation.Lat,
      lng: branch.Address.BranchGeoLocation.Lng
    },
    zoom: branch.Address.BranchGeoLocation.Zoom,
    title: "WaFd Bank " + branch.Title,
    height: "350px",
    width: "100%",
    markers: [
      {
        lat: branch.Address.BranchGeoLocation.Lat,
        lng: branch.Address.BranchGeoLocation.Lng,
        text: branch.Title
      }
    ]
  };

  const makeAppointmentUrl = "/make-an-appointment?BranchName=" + branch.MSBookingsBranchName;
  const appointmentButtonData = {
    id: "appointment-cta",
    containerClass: "mb-4",
    type: "link",
    class: "btn btn-primary w-100 w-lg-100 w-sm-auto",
    text: "Make an Appointment",
    url: makeAppointmentUrl,
    showIcon: false
  };

  const openAccountBtnData = {
    id: "open-account-cta",
    containerClass: "mb-4",
    type: "link",
    class: "btn-link w-100 w-lg-100 w-sm-auto",
    text: "Open an Account",
    url: "/open-bank-account-online",
    showIcon: false
  };

  const defaultMetaTitle = "Bank in " + branch.Address.City + " ," + branch.Address.State;
  const defaultMetaDescription =
    "Welcome to WaFd Bank we provide checking, savings, and mortgage services in the communities we serve. Visit our " +
    branch.Address.City +
    " " +
    branch.Address.State +
    " " +
    "branch!";

  // console.log(
  //   `Found ${branch.loan_officers.length} Personal Bankers and ${branch.mortgage_bankers.length} Mortgage Bankers`
  // );
  let loanOfficers = [...branch.loan_officers, ...branch.mortgage_bankers.filter((mb) => mb.Active === true)];
  // console.log(`Found ${loanOfficers.length} Loan Officers (total)`);

  // Find the branch manager so we can display them first
  const branchManager = loanOfficers.find((lo) => lo.JobTitle === "BranchManager");
  if (loanOfficers && branchManager) {
    loanOfficers = loanOfficers.filter((n) => n.JobTitle !== "BranchManager");
    loanOfficers.unshift(branchManager);
  }

  // Seattle Downtown Branch schema is the default schema
  const defaultSchema = {
    "@context": "http://schema.org",
    "@type": "BankOrCreditUnion",
    name: "Downtown Seattle",
    address: {
      "@type": "PostalAddress",
      streetAddress: "425 Pike St",
      addressLocality: "Seattle",
      addressRegion: "WA",
      addressCountry: "United States",
      postalCode: "98101"
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "47.610385",
      longitude: "-122.335771"
    },
    branchCode: "1006",
    url: "https://www.wafdbank.com/locations/washington/seattle/downtown",
    image: "https://www.wafdbank.com/images/default-source/wafd-logo/branch-locations/wafd-bank-in-washington",
    telephone: "(206) 204-3446",
    email: "downtownseattle.office@wafd.com",
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Monday",
        opens: "9:00:00",
        closes: "17:00:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Tuesday",
        opens: "9:00:00",
        closes: "17:00:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Wednesday",
        opens: "9:00:00",
        closes: "17:00:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Thursday",
        opens: "9:00:00",
        closes: "17:00:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: "http://schema.org/Friday",
        opens: "9:00:00",
        closes: "17:30:00"
      }
    ],
    parentOrganization: {
      "@type": "Organization",
      name: "WaFd Bank",
      logo: "https://www.wafdbank.com/images/default-source/wafd-logo/logos/wafd-bank-logo-vert-stack-png.png",
      url: "https://www.wafdbank.com/",
      sameAs: [
        "https://www.facebook.com/wafdbank",
        "https://twitter.com/wafdbank",
        "https://linkedin.com/company/wafd-bank",
        "https://www.youtube.com/user/wafedweb",
        "https://en.wikipedia.org/wiki/Washington_Federal"
      ]
    }
  };

  function removeUnderscoreFromObj(obj, prop) {
    if (prop.substring(0, 1) === "_") {
      const newProp = "@" + prop.substring(1);
      obj[newProp] = obj[prop];
      delete obj[prop];
    }
    return obj;
  }

  function removeUnderscoreFromArray(array, prop) {
    if (Array.isArray(array[prop])) {
      let a = 0;
      for (a; a < array[prop].length; a++) {
        for (const obj in array[prop][a]) {
          array[prop][a] = removeUnderscoreFromArray(array[prop][a], obj);
          array[prop][a] = removeUnderscoreFromObj(array[prop][a], obj);
        }
      }
    }
    return array;
  }

  function removeUnderscore() {
    let newSchema = { ...branch.SEO.Schema };
    for (const prop in newSchema) {
      for (const childProp in newSchema[prop]) {
        newSchema[prop] = removeUnderscoreFromArray(newSchema[prop], childProp);
        newSchema[prop] = removeUnderscoreFromObj(newSchema[prop], childProp);
      }
      newSchema = removeUnderscoreFromArray(newSchema, prop);
      newSchema = removeUnderscoreFromObj(newSchema, prop);
    }
    return newSchema;
  }

  // provide the static URLs for og:image meta tags
  const branchOgImage = branch.OgImageUrl
    ? `https://www.wafdbank.com` + branch.OgImageUrl
    : branch.BranchImageUrl
    ? `https://www.wafdbank.com${branch.BranchImageUrl}`
    : `https://www.wafdbank.com` + getSrc(data.defaultBranchPhoto);

  const SEOData = {
    title: branch.SEO.MetaTitle || defaultMetaTitle,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: branch.SEO.MetaTitle || defaultMetaTitle
      },
      {
        name: "description",
        property: "og:description",
        content: branch.SEO.MetaDescription || defaultMetaDescription
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com" + branch.PageURL
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: branchOgImage
      }
    ],
    schema: removeUnderscore(branch.SEO.Schema) || defaultSchema
  };

  const viewInGoogleMapUrl =
    "https://maps.google.com/maps?daddr=" +
    branch.Address.StreetAddress +
    "%2c" +
    branch.Address.City +
    "%2c" +
    StateCode +
    "%2c" +
    branch.Address.ZipCode;

  const accordionData = [
    {
      title: "Branch Services",
      component: (
        <BranchServices
          {...{
            services: branch.BranchServices
          }}
        />
      ),
      condition: branch.BranchServices
    },
    {
      title: "Lobby Hours",
      component: (
        <>
          <DailyHours {...branch.LobbyHours} />{" "}
          <p>
            <button
              className={`anchor-link text-decoration-none ${styles.buttonTransparent}`}
              onClick={handleShowModal}
            >
              <Icon name="calendar" lib="far" class="mr-2" />
              2024 Holidays
            </button>
          </p>
        </>
      ),
      condition: branch.LobbyHours
    },
    {
      title: "Drive Thru Hours",
      component: <DailyHours {...branch.DriveUpHours} />,
      condition: branch.DriveUpHours
    }
  ];

  const initialState = Array(accordionData.length).fill(false);

  const [collapseClass, setCollapseClass] = useState(initialState);
  function updateCollapseState(index) {
    const newState = [...initialState];
    newState[index] = !collapseClass[index];
    setCollapseClass(newState);
  }

  const [showLOModalMortgage, setShowLOModalMortgage] = useState(false);
  const handleCloseLOModalMortgage = () => setShowLOModalMortgage(false);
  const handleShowLOModalMortgage = () => {
    setShowLOModalMortgage(true);
  };

  const [showLOModalHomeEquity, setShowLOModalHomeEquity] = useState(false);
  const handleCloseLOModalHomeEquity = () => setShowLOModalHomeEquity(false);
  const handleShowLOModalHomeEquity = () => {
    setShowLOModalHomeEquity(true);
  };

  let iconBlocksData = [
    {
      text: "Open a Personal Account Online",
      src: "/images/icons/personal-accounts-icon.svg",
      url: "/open-bank-account-online"
    },
    {
      text: "Open a Small Business Account Online",
      src: "/images/icons/business-accounts-icon.svg",
      url: "/business-banking/open-business-bank-account-online"
    },
    {
      text: "Already have an Account? Sign up for online access",
      src: "/images/icons/enroll-online-access-icon.svg",
      url: "https://online.wafdbank.com/link/register?application=OLB",
      externalLink: true
    },
    {
      text: "Apply for a Home Loan",
      src: "/images/icons/house-with-heart.svg",
      onClick: handleShowLOModalMortgage
    },
    {
      text: "Apply for a Home Equity Loan",
      src: "/images/icons/heloc-loans-icon-96.svg",
      onClick: handleShowLOModalHomeEquity
    },
    {
      text: "Questions? Contact a Loan Officer",
      src: "/images/icons/home-loans-icon.svg",
      url: "/personal-banking/contact-loan-officer"
    }
  ];

  const hasDisruptionNotice = branch && branch.DisruptionNotice && branch.DisruptionNotice.trim().length !== 0;

  useEffect(() => {
    const reviewsContainer = document.getElementById("branch-reviews");
    if (reviewsContainer) {
      // console.log('Found branch-reviews');
      const observer = new MutationObserver((mutationsList, observer) => {
        const reviewTextDiv = reviewsContainer.querySelector(".reviewLink");
        if (reviewTextDiv) {
          // console.log('Found reviewLink inside reviews-container');
          const anchorElement = reviewTextDiv.querySelector("a");
          if (anchorElement) {
            anchorElement.textContent = "Leave a Review";
            // console.log('Anchor text updated');
            observer.disconnect();
          } else {
            // console.log('Anchor element not found inside reviewLink');
          }
        } else {
          // console.log('reviewLink not found inside branch-reviews');
        }
      });

      observer.observe(reviewsContainer, { childList: true, subtree: true });

      return () => observer.disconnect();
    } else {
      // console.log('reviews-container not found');
    }
  }, []);

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb {...{ location }} />
      {/* Disruption Notice */}
      {hasDisruptionNotice && (
        <section className="container pb-0">
          <NotificationAlert
            type="warning"
            id="branch-disruption-notification-alert"
            class="mb-0"
            bodyText={branch.DisruptionNotice}
          />
        </section>
      )}
      {/* Branch Info Section */}
      <section className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="mb-3">WaFd Bank {branch.Title}</h1>
            <div className="row">
              <div className={branch.MSBookingsBranchName ? "col-lg-6" : "col-12"}>
                {/* Branch Address */}
                <div className="d-flex mb-3">
                  <Icon name="map-marker-alt" lib="far" class="text-primary mr-2 mt-1" />
                  <a href={viewInGoogleMapUrl} className="text-decoration-none" id="branch-address-cta">
                    {branch.Address.StreetAddress}, {branch.Address.City},{" "}
                    {branch.Address.State === "NewMexico" ? "New Mexico" : branch.Address.State}&nbsp;&nbsp;
                    {branch.Address.ZipCode}
                  </a>
                </div>
                {/* Today Branch Hours */}
                <div className="mb-3 d-flex">
                  <Icon name="clock" lib="far" class="text-primary mr-2 mt-1" />
                  <span
                    id="today-branch-hours"
                    dangerouslySetInnerHTML={{
                      __html: todaysLobbyHoursHtml
                    }}
                  ></span>
                </div>
                {/* Branch Phone Number */}
                <div className="mb-3">
                  <Icon name="phone" lib="far" class="text-primary mr-2" />
                  <a className="text-decoration-none" id="branch-phone-cta" href={`tel:${branch.PhoneNumber}`}>
                    {branch.PhoneNumber}
                  </a>{" "}
                  (Phone)
                </div>
              </div>
              <div className={`col-lg-6 ${branch.MSBookingsBranchName ? "order-1 order-lg-0" : "order-1"}`}>
                {branch.MSBookingsBranchName && <Button {...appointmentButtonData} />}
                <Button {...openAccountBtnData} />
              </div>
              {/* Branch Email Address */}
              <div className="mb-3 col-12">
                <Icon name="envelope" lib="far" class="text-primary mr-2" />
                <a className="text-decoration-none" id="branch-email-cta" href={`mailto:${branch.EmailAddress}`}>
                  {branch.EmailAddress}
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <GoogleMapSimple {...GMData} />
          </div>
        </div>
      </section>
      {/* Branch Services Section */}
      <section className="bg-light pb-0">
        <div className="container">
          <div className="row">
            {/* Branch or Default Photo */}
            <div className="col-md-4">
              {branch.BranchImageUrl ? (
                <img
                  src={branch.BranchImageUrl}
                  className="border-radius-12 mw-100"
                  alt={`WaFd Bank in ${branch.Address.City}, ${branch.Address.State} #${branch.BranchId} - Washington Federal.`}
                />
              ) : null}
              {/* Branch Services */}
              <div className="d-none d-md-block">
                <div className="branchServices my-3">
                  <BranchServices
                    {...{
                      services: branch.BranchServices
                    }}
                  />
                </div>
              </div>
            </div>
            {/* Lobby Hours */}
            <div className="d-none d-md-block col-md-4">
              <div className="lobbyHours pr-lg-5">
                <h3>Lobby Hours</h3>
                <DailyHours {...branch.LobbyHours} />
              </div>
              <p>
                <button
                  className={`anchor-link text-decoration-none ${styles.buttonTransparent}`}
                  onClick={handleShowModal}
                >
                  <Icon name="calendar" lib="far" class="mr-2" />
                  2024 Holidays
                </button>
              </p>
            </div>
            {/* Current Year Holidays Modal */}
            <Modal show={showHolidayModal} onHide={handleCloseModal}>
              <Modal.Header className="border-0" closeButton></Modal.Header>
              <Modal.Body className="pt-0">
                <HolidaysModal />
              </Modal.Body>
              <Modal.Footer>
                <button
                  className={`text-left text-black font-weight-bold ${styles.buttonTransparent}`}
                  style={{ cursor: "pointer" }}
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </Modal.Footer>
            </Modal>
            {/* Drive Thru Hours */}
            <div className="d-none d-md-block col-md-4">
              {branch.BranchServices.DriveThroughBanking && (
                <div className="driveUpHours border-left pl-3 pl-lg-5">
                  <h3>Drive Thru Hours</h3>
                  <DailyHours {...branch.DriveUpHours} />
                </div>
              )}
            </div>
          </div>
          {/* Branch Services Mobile Section */}
          {/* Mobile Accordions */}
          <div className="mt-4 d-block d-md-none" id="branch-accordions">
            {accordionData.map((accordion, index) => {
              return (
                accordion.condition && (
                  <div className={`${"border-top"}`} key={index}>
                    <button
                      id={`accordion-${index}`}
                      onClick={(e) => {
                        updateCollapseState(index);
                      }}
                      className={`border-0 w-100 d-flex align-items-center bg-light `}
                      aria-controls={`accordion-${index}`}
                      aria-expanded={collapseClass[index]}
                      aria-labelledby={`accordion-${index}`}
                    >
                      <Icon class="fa-2x text-success mx-2 my-1" name={`${collapseClass[index] ? "minus" : "plus"}`} />
                      {accordion.title}
                    </button>
                    <Collapse in={collapseClass[index]}>
                      <div className="py-3 ml-4 pl-3">{accordion.component}</div>
                    </Collapse>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </section>
      {/* Branch Manager and Loan Officers */}
      {loanOfficers.length > 0 && (
        <section className="container">
          <h2 className="font-weight-semi-bold">Meet our {branch.Title} Team</h2>
          <div className="row">
            {loanOfficers.map((loanOfficer, index) => {
              return (
                <div className="col-sm-6 col-lg-4 mb-5" key={index}>
                  <div className="row">
                    <div className="col-auto mb-3">
                      <BankerHeadshot email={loanOfficer.EmailAddress} />
                    </div>
                    <div className="col">
                      <h5 className="mb-2">
                        {loanOfficer.FirstName} {loanOfficer.LastName}
                      </h5>
                      <p className="mb-3">{loanOfficer.JobTitle.replace(/([A-Z])/g, " $1").trim()}</p>
                      <a href={`mailto:${loanOfficer.EmailAddress}`} className="btn btn-link d-none d-sm-block">
                        Email {loanOfficer.FirstName}{" "}
                        <Icon name="envelope-open-text" lib="far" class="text-primary ml-2" />
                      </a>
                    </div>
                    <div className="col d-block d-sm-none">
                      <a href={`mailto:${loanOfficer.EmailAddress}`} className="btn btn-link btn-block">
                        Email {loanOfficer.FirstName}{" "}
                        <Icon name="envelope-open-text" lib="far" class="text-primary ml-2" />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      )}

      <section className="bg-light">
        <div className="container">
          {StateCode === "CA" ? (
            <h2 className="text-success">Best Bank in {branch.Address.State}</h2>
          ) : (
            <h2 className="text-success">
              Voted Best Bank in {branch.Address.State === "NewMexico" ? "New Mexico" : branch.Address.State}
            </h2>
          )}
          <div className="row">
            <div className="col-md-6">
              {StateCode === "CA" ? (
                <>
                  <p>
                    Walk in our branch and you'll see this is your neighborhood bank, with bankers that care about
                    knowing your name and helping you find financial success right here in the community. Whether you're
                    a business in the San Jose area or an individual looking for banking services to help strengthen
                    your financial future, WaFd Bank can help. From{" "}
                    <Link
                      id="free-checking-link"
                      to={`/personal-banking/free-checking-account/${branch.branch_state.Slug}`}
                    >
                      {branch.Address.State} free checking
                    </Link>{" "}
                    accounts to{" "}
                    <Link id="small-business-banking-link" to="/business-banking/small-business">
                      small business banking
                    </Link>{" "}
                    and{" "}
                    <Link id="commercial-lending-link" to="/commercial-banking/commercial-lending">
                      commercial lending services
                    </Link>
                    , WaFd Bank is here for all of your banking needs.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Walk in our branch and you'll see this is your neighborhood bank, with bankers that care about
                    knowing your name and helping you find financial success right here in the community. Whether you're
                    a business in the {branch.Address.City} area or an individual looking for banking services to help
                    strengthen your financial future, WaFd Bank can help. From{" "}
                    <Link
                      id="free-checking-link"
                      to={`/personal-banking/free-checking-account/${branch.branch_state.Slug}`}
                    >
                      {branch.Address.State === "NewMexico" ? "New Mexico" : branch.Address.State} free checking
                    </Link>{" "}
                    accounts and{" "}
                    <Link id="home-loans-link" to="/personal-banking/home-loans">
                      mortgages
                    </Link>{" "}
                    to{" "}
                    <Link id="small-business-banking-link" to="/business-banking/small-business">
                      small business banking
                    </Link>{" "}
                    and{" "}
                    <Link id="commercial-lending-link" to="/commercial-banking/commercial-lending">
                      commercial lending services
                    </Link>
                    , WaFd Bank is here for all of your banking needs.
                  </p>
                  {StateCode === "ID" && (
                    <div className="row">
                      <div className="col-md-8">
                        <img
                          src="/images/wafd-bank-idaho-best-regional-bank-2024.png"
                          alt="Idaho Business Review Reader Rankings 2024 Best Regional bank Winner badge."
                          className="w-100"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="col-md-6">
              <p>
                Or if you're looking for a great online and mobile banking experience, you can open an account or bank
                with us anytime, anywhere, from your computer, tablet or smart phone.
              </p>
              <p className="text-success font-weight-bold text-sm mb-2">Get the WaFd Bank mobile app</p>
              <div className="row">
                <div className="col-auto d-none d-md-block">
                  <StaticImage
                    src="../../images/wafd-mobile-app-qr-code.png"
                    alt="WaFd App QR Code"
                    placeholder="blurred"
                    quality="100"
                  />
                  <p className="text-secondary text-xs text-center">Scan to download</p>
                </div>
                <div className="ml-3">
                  <div className="mb-3">
                    <MobileAppDownloadBtn
                      idName="google-play-store"
                      storeBtn="google"
                      url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
                      mt={0}
                      mb={-7}
                    />
                    <p className="text-secondary text-xs text-center mt-1 mb-0">For Android devices</p>
                  </div>
                  <div className="mb-3">
                    <MobileAppDownloadBtn
                      idName="apple-store"
                      storeBtn="apple"
                      url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
                      mb={-6}
                    />
                    <p className="text-secondary text-xs text-center mt-1 mb-0">For Apple devices</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Icon Blocks */}
      <section className="border-bottom" id="branch-icon-blocks-container">
        <div className="container">
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 no-gutters">
            {iconBlocksData.map((data, index) => (
              <div
                className="d-flex d-sm-block position-relative px-sm-3 text-sm-center align-items-center"
                key={index}
                id={`branch-icon-block-${index + 1}`}
              >
                {data.onClick ? (
                  <button
                    id={`branch-icon-block-${index + 1}-link`}
                    onClick={data.onClick}
                    className="btn-anchor-link stretched-link"
                  >
                    <span className="sr-only">{data.text}</span>
                  </button>
                ) : data.externalLink ? (
                  <a
                    id={`branch-icon-block-${index + 1}-link`}
                    className="stretched-link"
                    href={data.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="sr-only">{data.text}</span>
                  </a>
                ) : (
                  <Link id={`branch-icon-block-${index + 1}-link`} className="stretched-link" to={data.url}>
                    <span className="sr-only">{data.text}</span>
                  </Link>
                )}
                <img src={data.src} className="mb-3 mr-3 mr-sm-0" alt={data.text} />
                <p className="font-weight-bold text-black">
                  {data.text}
                  <Icon name="arrow-right" class="ml-2 text-primary" lib="fas" />
                </p>
              </div>
            ))}
          </div>
        </div>
        <LoanOfficerSearch
          isModalSearch={true}
          show={showLOModalMortgage}
          handleClose={handleCloseLOModalMortgage}
          loanType="Home Loan"
        />
        <LoanOfficerSearch
          isModalSearch={true}
          show={showLOModalHomeEquity}
          handleClose={handleCloseLOModalHomeEquity}
          loanType="HELOC" // can use both HELOC and HELOAN
        />
      </section>
      {branch.YextCode && (
        <section className="bg-white">
          <div className="container">
            <h3>Branch Reviews</h3>
            <div id="branch-reviews"></div>
          </div>
        </section>
      )}
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

BranchTemplate.propTypes = {
  branch: PropTypes.object
};

export default BranchTemplate;
